<template>
  <div>
    <section class="dashboard">
      <h1>Dashboard</h1>
      <div class="stats">
        <div class="box">
          <div class="wrapper">
            <h2>0</h2>
            <h4>Recompensas</h4>
            <h5>Convertidas </h5>
          </div>
        </div>
        <div class="box">
          <div class="wrapper">
            <h2>0</h2>
            <h4>Personas</h4>
            <h5>Vieron recompensa</h5>
          </div>
        </div>
      </div>


      <div class="last">
        <div class="wrapper">
        <h2>Últimas recompensas convertidas</h2>
        <table class="data">
          <tr><td>25 Mayo</td><td>0</td></tr>
          <tr><td>24 Mayo</td><td>5</td></tr>
          <tr><td>23 Mayo</td><td>10</td></tr>
          <tr><td>22 Mayo</td><td>15</td></tr>
          <tr><td>21 Mayo</td><td>20</td></tr>
          <tr><td>20 Mayo</td><td>25</td></tr>
        </table>
        </div>
      </div>
    </section>

    <div class="stats">mensual cuanto va</div>

  </div>

</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
section.dashboard .stats { display:flex; align-items:center;}
section.dashboard .stats .box {  width:50%; }
section.dashboard .stats .box h2 { margin:0; padding:0; font-size:80px; }
section.dashboard .stats .box h4 { margin:0; padding:0; font-size:18px; }
section.dashboard .stats .box h5 {margin:0; padding:0; font-size:15px; color:#a3a6ad;  }
section.dashboard .stats .box .wrapper { border-radius:10px; margin:15px; background:#7e7e7e1f}

section.dashboard .last .wrapper { padding:20px;}
section.dashboard .last table { width:100%; background:#7e7e7e1f;}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
