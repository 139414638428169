<template>
  <header>
    <div class="logo"><img src="./assets/logo.png" /></div>
    <div class="logout"></div>
  </header>
  <router-view/>
  <footer>
  <nav>
    <router-link to="/">Home</router-link> |
    <a href="#" @click="startScanner()">SCANNER</a>|
    <router-link to="/about">REPORTES</router-link>
  </nav>
  </footer>
  <section ref="scanner" class="qr">
    <a href="javascript:void(0)" @click="closeScanner" class="btn close"><span>Close</span></a>
    <div class="status">
    icno (Escanee el código para validar su estatus)
    </div>
  </section>
</template>
<script>
export default {
  name: "App",
  props: { },     // Props
  data() {
  },
  computed: { },  // Computed props
  methods: {
    startScanner : function(){
      this.$refs.scanner.className = "qr on";
    },
    closeScanner : function(){
      this.$refs.scanner.classList.remove = "on";
    },
  },
  mounted() {  }
}
</script>

<style>
body { background:#0c0a2b;}
a { text-decoration:none;}
header { border-bottom:#494c55 solid 1px; min-height:60px;}
header .logo { max-width:270px; padding-left:10px; padding-top:10px;}
header .logo img { width:100%;}

footer  { position:fixed; bottom:0; left:0; right:0; width:100%; height:80px;  }
footer nav {
background: #080624;
width:90%; min-height:60px; border-radius:12px; position:absolute; left:15px; right:10px; margin:auto; border:#1e1b36 solid 2px;
display:flex; justify-content: space-between; align-items: center;
}
footer nav a{
color:#fff; font-size:14px; text-transform:uppercase;  min-width:120px; font-weight:500; }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
}

section.qr { position:fixed; background:rgba(255,255,255,.90); top:0; left:0; right:0; bottom:0; display:none; }
section.qr.on { display:block;}
section.qr a { width:50px; height:50px; border:#fff solid 2px; position:absolute; top:10px; right:10px; border-radius:1000px;}
section.qr a span { display:flex; width:100%; height:100%; align-items:center; justify-content:center; }
section.qr .status { position:absolute; width:100%; height:60px; background:#fff; bottom:0;}

</style>
